import React, { Component } from 'react';
import './optionsPanel.scss';

class OptionsPanel extends Component {
  render () {
    return (
      <React.Fragment>
        <div className="option option-selectDevice">
          <div className="optionHeading">
            Select Device
          </div>
          <div className="optionValue">
            <select name="selectDevice" onChange={this.props.updateDevice} >
              {
                this.props.deviceList.map((item) => (
                  <option 
                    key={item.code}
                    type="radio" 
                    name="devices" 
                    value={item.code} 
                    onChange={this.props.updateDevice} 
                    checked={item.code === this.props.selectedDevice}
                    >
                    {item.name}
                  </option>
                ))
              }
            </select>
          </div>
        </div>

        {this.props.deviceList.map((device) => 
          device.code === this.props.selectedDevice && device.colors ? 
            <div className="option option-selectColor">
              <div className="optionHeading">Select Color</div>
              <div className="optionValue">
              {
                device.colors.map((color) => 
                  <label className={"colorOption " + color + (color === this.props.selectedColor ? " active" : "")} key={color}>
                    <input 
                    type="radio" 
                    name="colors" 
                    value={color}
                    onChange={this.props.updateColor} 
                    checked={color === this.props.selectedColor} 
                    />
                  </label>
                ) }
              </div>
            </div>
          : ""
        )}

        <div className="option option-uploadImage">
          <div className="optionHeading">
            Screenshot
            <div className="popup deviceHelperPopup">
              <div className="popupTrigger">&#x2139;</div>
              <div className="popupContent">
                <div className="contentWrap">
                  <p>Screenshot guide: </p>
                  <ul>
                    <li>
                      iPhone X/XS: <span>1125x2436 pixels</span>
                    </li>
                    <li>
                      iPhone XS Max: <span>1242x2688 pixels</span>
                    </li>
                    <li>
                      Galaxy Note 8: <span>1440x2960 pixels</span>
                    </li>
                    <li>
                      iPhone 6/7/8: <span>750x1334 pixels</span>
                    </li>
                    <li>
                      iPhone 6/7/8 plus: <span>1242x2208 pixels</span>
                    </li>
                    <li>
                      iPhone 5s/5c: <span>640x1136 pixels</span>
                    </li>
                    <li>
                      iPad Mini: <span>1536x2048 pixels</span>
                    </li>
                    <li>
                      MacBook Pro: <span>1280x800 pixels</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="optionValue">
            <div className="uploadWrapper">
              <button className="btn upload-btn">
                {this.props.image ? "Change Screenshot" : "Add Screenshot"}
              </button>
              <input type="file" name="myFile" onChange={this.props.updateImage}/>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default OptionsPanel;