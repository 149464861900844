import React, {Component} from 'react';
import OptionsPanel from "./components/OptionsPanel";
import MockupArea from "./components/MockupArea";
import domtoimage from 'dom-to-image';
import { saveAs } from "file-saver";
import 'normalize.css';
import './styles/devices.min.css';
import logo from './images/logo.svg';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      device: "iphone-x",
      color: "black",
      image: null,
      deviceList: [
        { code: "iphone-x", name: "iPhone X/XS/Max"}, 
        { code: "note8", name: "Galaxy Note 8"},
        { code: "iphone8", name: "iPhone 8", colors: ["black", "silver", "gold"]},
        { code: "iphone8plus", name: "iPhone 8 Plus", colors: ["black", "silver", "gold"]},
        { code: "iphone5s", name: "iPhone 5s", colors: ["black", "silver", "gold"]},
        { code: "iphone5c", name: "iPhone 5c", colors: ["white", "red", "yellow", "green", "blue"]},
        { code: "ipad", name: "iPad Mini", colors: ["black", "silver"]},
        { code: "macbook", name: "MacBook Pro"}],
    };
  }

  changeImage = (event) => {
    this.setState({
      image: URL.createObjectURL(event.target.files[0])
    })
  };

  changeDevice = (event) => {
    this.setState({
      device: event.target.value
    });
    
    if (event.target.value === "iphone5c") {
      this.setState({
        color: "red"
      });
    }
    else {
      this.setState({
        color: "black"
      });
    }
  };

  changeColor = (event) => {
    this.setState({
      color: event.target.value
    });
  };

  saveAsPNG = () => {
    domtoimage.toBlob(document.querySelector('.phone'))
    .then((blob) => {
        saveAs(blob, 'mockup.png');
    });
  };

  render () {
    return (
      <div className="App">
        <div className="headerInfo">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="Qurb"/>
            </a>
          </div>
          <div className="app-description">Web-based free device mockup generator</div>
          <div style={{maxWidth: "500px", margin: "20px auto 0 auto", background: "#355653", padding: "11px 10px", borderRadius: "20px", fontSize: "16px", color: "#b9b9b9"}}>
            Check out my other project to <br />
            <a target="_blank" rel="noopener noreferrer" href="https://pika.style" style={{color: "#2cb390"}}>create website and browser mockups</a>
          </div>
          <div className="dev-description">Made with <span>&hearts;</span> in India by <a target="_blank" rel="noopener noreferrer" href="https://rishimohan.me/">Rishi Mohan</a> | <a target="_blank" rel="noopener noreferrer" href="https://kizie.co/thelifeofrishi">Follow on Twitter</a></div>
        </div>

        <div className="mockupArea">
          <MockupArea
            image={this.state.image}
            selectedDevice={this.state.device}
            selectedColor={this.state.color}
          />
        </div>

        <div className="playground">
          <div className="optionsPanel">
            <OptionsPanel 
              deviceList={this.state.deviceList}
              colorOptions={this.state.colorOptions}
              selectedDevice={this.state.device}
              selectedColor={this.state.color}
              image={this.state.image}
              updateDevice={this.changeDevice}
              updateColor={this.changeColor}
              updateImage={this.changeImage}
            />

            <div className="saveActions">
              <button className="btn" onClick={this.saveAsPNG}>Save mockup</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
