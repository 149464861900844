import React, { Component } from 'react';
import './mockupArea.scss';

class MockupArea extends Component {
  showiPhoneX = () => {
    return (
      <div className="marvel-device iphone-x">
        <div className="notch">
            <div className="camera"></div>
            <div className="speaker"></div>
        </div>
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="bottom-bar"></div>
        <div className="volume"></div>
        <div className="overflow">
            <div className="shadow shadow--tr"></div>
            <div className="shadow shadow--tl"></div>
            <div className="shadow shadow--br"></div>
            <div className="shadow shadow--bl"></div>
        </div>
        <div className="inner-shadow"></div>
        <div className="screen">
          {this.props.image ? <img src={this.props.image} alt="Uploaded Screenshot" className="uploadedScreen" /> : ""}
        </div>
      </div>
    );
  };

  showNote8 = () => {
    return (
      <div className="marvel-device note8">
          <div className="inner"></div>
          <div className="overflow">
              <div className="shadow"></div>
          </div>
          <div className="speaker"></div>
          <div className="sensors"></div>
          <div className="more-sensors"></div>
          <div className="sleep"></div>
          <div className="volume"></div>
          <div className="camera"></div>
          <div className="screen">
            {this.props.image ? <img src={this.props.image} alt="Uploaded Screenshot" className="uploadedScreen" /> : ""}
          </div>
      </div>
    );
  };

  showiPhone8 = () => {
    return (
      <div className={"marvel-device iphone8 " + this.props.selectedColor}>
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="volume"></div>
        <div className="camera"></div>
        <div className="sensor"></div>
        <div className="speaker"></div>
        <div className="screen">
          {this.props.image ? <img src={this.props.image} alt="Uploaded Screenshot" className="uploadedScreen" /> : ""}
        </div>
        <div className="home"></div>
        <div className="bottom-bar"></div>
      </div>
    );
  };

  showiPhone8Plus = () => {
    return (
      <div className={"marvel-device iphone8plus " + this.props.selectedColor}>
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="volume"></div>
        <div className="camera"></div>
        <div className="sensor"></div>
        <div className="speaker"></div>
        <div className="screen">
          {this.props.image ? <img src={this.props.image} alt="Uploaded Screenshot" className="uploadedScreen" /> : ""}
        </div>
        <div className="home"></div>
        <div className="bottom-bar"></div>
      </div>
    );
  };

  showiPhone5s = () => {
    return (
      <div className={"marvel-device iphone5s " + this.props.selectedColor}>
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="volume"></div>
        <div className="camera"></div>
        <div className="sensor"></div>
        <div className="speaker"></div>
        <div className="screen">
          {this.props.image ? <img src={this.props.image} alt="Uploaded Screenshot" className="uploadedScreen" /> : ""}
        </div>
        <div className="home"></div>
        <div className="bottom-bar"></div>
      </div>
    );
  };

  showiPhone5c = () => {
    return (
      <div className={"marvel-device iphone5c " + this.props.selectedColor}>
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="volume"></div>
        <div className="camera"></div>
        <div className="sensor"></div>
        <div className="speaker"></div>
        <div className="screen">
          {this.props.image ? <img src={this.props.image} alt="Uploaded Screenshot" className="uploadedScreen" /> : ""}
        </div>
        <div className="home"></div>
        <div className="bottom-bar"></div>
      </div>
    );
  };

  showiPadMini = () => {
    return (
      <div className={"marvel-device ipad " + this.props.selectedColor}>
        <div className="camera"></div>
        <div className="screen">
          {this.props.image ? <img src={this.props.image} alt="Uploaded Screenshot" className="uploadedScreen" /> : ""}
        </div>
        <div className="home"></div>
      </div>
    );
  };

  showMacBookPro = () => {
    return (
      <div className="marvel-device macbook">
        <div className="top-bar"></div>
        <div className="camera"></div>
        <div className="screen">
          {this.props.image ? <img src={this.props.image} alt="Uploaded Screenshot" className="uploadedScreen" /> : ""}
        </div>
        <div className="bottom-bar"></div>
      </div>
    );
  };

  showDevice = (selectedDevice) => {
    console.log(selectedDevice);
    switch(selectedDevice) {
      case "iphone-x": return (this.showiPhoneX());
      case "note8" : return (this.showNote8());
      case "iphone8" : return (this.showiPhone8());
      case "iphone8plus" : return (this.showiPhone8Plus());
      case "iphone5s" : return (this.showiPhone5s());
      case "iphone5c" : return (this.showiPhone5c());
      case "ipad" : return (this.showiPadMini());
      case "macbook" : return (this.showMacBookPro());
      default: return (this.showiPhoneX());
    };
  };

  render() {
    return (
      <div className={"phone" + ( this.props.image ? " imageUploaded" : "")}>
        {this.showDevice(this.props.selectedDevice)}
      </div>
    );
  }
}

export default MockupArea;